import React from "react"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import HTMLHead from "../components/HTMLHead"

const NotFoundPage = () => {
  return (
    <Layout key="404">
      <h1>404</h1>
      <p>
        Page not found. Do you want to go <Link to="/">Home</Link>?
      </p>
    </Layout>
  )
}

export default NotFoundPage

export const Head = () => <HTMLHead pageTitle="Not Found" />
